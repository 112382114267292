import { EntityBaseCache, Favorites } from "@core/models";

export class FavoritesCache extends EntityBaseCache {
  // ==================== Class Properties ====================
  static code = "favorites_cache";

  protected static _instance: FavoritesCache;

  // ==================== Instance Properties ====================
  protected _items: Favorites[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  get entityClass() {
    return Favorites;
  }

  get itemClass() {
    return Favorites;
  }

  get items(): Favorites[] {
    return this._items;
  }
}
