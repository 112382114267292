import { Featuring } from "./featuring";

export class Favorites extends Featuring {
  // ==================== Class Properties ====================
  static code = "favorites";

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
}
