import { Entity } from "@core/models";

export class ContentBlock extends Entity {
  // ==================== Class Properties ====================
  static code = "content_block";

  // ==================== Instance Properties ====================
  content: string;
  visibility: boolean;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.content = data.content;
    this.visibility = data.visibility;
  }
}
