import _ from "lodash";

import { Env } from "@stencil/core";

import type { OrderBy } from "@core/types";

export type MetaType = "entity" | "entity_cache" | "summary" | "summary_cache";

class Metadata {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  get meta() {
    return JSON.parse(Env.meta);
  }

  get(classCode: string, propCode: string, metaType: MetaType = "entity") {
    let result: boolean | number | string | string[];
    switch (metaType) {
      case "entity":
        result = this.getEntityProp(classCode, propCode);
        break;
      case "entity_cache":
        result = this.getEntityCacheProp(classCode, propCode);
        break;
      case "summary":
        result = this.getEntitySummaryProp(classCode, propCode);
        break;
      case "summary_cache":
        result = this.getEntitySummaryCacheProp(classCode, propCode);
        break;
    }
    return result;
  }

  getEntityProp(classCode: string, propCode: string) {
    const metaType = "entity";
    let valuePropNotExists: boolean | number | string | string[];
    switch (propCode) {
      // boolean ====================
      case "multilingual":
      case "usesSubscription":
        valuePropNotExists = false;
        break;
      // string ====================
      case "collPath":
      case "codeHybrid":
        valuePropNotExists = "";
        break;
    }
    const result =
      this.meta[metaType][classCode][propCode] || valuePropNotExists;
    return result;
  }

  getEntityCacheProp(classCode: string, propCode: string) {
    const metaType = "entity_cache";
    let valuePropNotExists: boolean | number | string | string[];
    switch (propCode) {
      // boolean ====================
      case "usesPromoted":
      case "usesFavorites":
      case "usesSubscription":
        valuePropNotExists = false;
        break;
      // string ====================
      case "orderByDefault":
        valuePropNotExists = "";
        break;
      // string[] ====================
      case "orderByProps":
        valuePropNotExists = [];
        break;
    }
    const result =
      this.meta[metaType][classCode][propCode] || valuePropNotExists;
    return result;
  }

  getEntitySummaryProp(classCode: string, propCode: string) {
    const metaType = "summary";
    let valuePropNotExists: boolean | number | string | string[];
    switch (propCode) {
      // string ====================
      case "collPath":
        valuePropNotExists = "";
        break;
    }
    const result =
      this.meta[metaType][classCode][propCode] || valuePropNotExists;
    return result;
  }

  getEntitySummaryCacheProp(classCode: string, propCode: string) {
    const metaType = "summary_cache";
    let valuePropNotExists: boolean | number | string | string[];
    switch (propCode) {
      // boolean ====================
      case "usesPromoted":
      case "usesFavorites":
      case "usesSubscription":
        valuePropNotExists = false;
        break;
      // string ====================
      case "orderByDefault":
        valuePropNotExists = "";
        break;
      // string[] ====================
      case "orderByProps":
        valuePropNotExists = [];
        break;
    }
    const result =
      this.meta[metaType][classCode][propCode] || valuePropNotExists;
    return result;
  }

  orderByDefault(
    classCode: string,
    metaType: MetaType = "entity"
  ): OrderBy | null {
    const valuePropNotExists = null;
    const result =
      this.meta[metaType][classCode].orderByDefault || valuePropNotExists;
    return result;
  }
}

export const metadata = new Metadata();
