import { Entity } from "@core/models";
import type { MetaType } from "@core/models";

import { EntityCoreCache } from "./entity.core.cache";

export class EntityCache extends EntityCoreCache {
  // ==================== Class Properties ====================
  /** class code */
  static code = "entity_cache";

  protected static _instance: EntityCache;

  // ==================== Instance Properties ====================
  protected _items: Entity[];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  get entityClass() {
    return Entity;
  }

  get items(): Entity[] {
    return this.items;
  }

  get kind(): MetaType {
    return "entity_cache";
  }
}
