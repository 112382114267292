import { EntityBase } from "@core/models";

export class Featuring extends EntityBase {
  // ==================== Class Properties ====================
  static code = "featuring";

  // ==================== Instance Properties ====================
  items: string[];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.items = data.items;
  }
}
