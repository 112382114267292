import { EntitySummary } from "@core/models";
import { ImageForCard } from "@cms-core/models";

import { Article } from "./entity";

export class ArticleSummary extends EntitySummary {
  // ==================== Class Properties ====================
  static code = "article_summary";

  // ==================== Instance Properties ====================
  dateOfPublication: Date;
  imageForCard: ImageForCard;
  slug: string;
  titleCard: string;

  // ==================== Class Methods ====================
  static get entityClass() {
    return Article;
  }

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.dateOfPublication = data.dateOfPublication.toDate();
    this.imageForCard = new ImageForCard(data.imageForCard);
    this.titleCard = data.titleCard;
    this.slug = data.slug;
  }
}
