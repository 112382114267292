import { EntityBaseCache, ConfigurableProperties } from "@core/models";

export class ConfigurablePropertiesCache extends EntityBaseCache {
  // ==================== Class Properties ====================
  static code = "configurable_properties_cache";

  protected static _instance: ConfigurablePropertiesCache;

  // ==================== Instance Properties ====================
  protected _items: ConfigurableProperties[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  get entityClass() {
    return ConfigurableProperties;
  }

  get itemClass() {
    return ConfigurableProperties;
  }

  get items(): ConfigurableProperties[] {
    return this._items;
  }

  getById(id: string) {
    let result = null;
    const index = this._items.findIndex((el) => el.id === id);
    if (index > -1) {
      result = this._items[index];
    }
    return result;
  }
}
