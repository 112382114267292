import { logEvent } from "firebase/analytics";

import { analytics, auth } from "./firebase";

import { getCookies } from "@core/helpers";

logEvent(analytics, "app_initialized");

const authObject = { auth: undefined };

const cookies = getCookies();

// get a consentCookie
const consentCookie = cookies.find(
  (cookie) => cookie.name === "cookies_accepted_categories"
);

const consentAnalytics = consentCookie?.value
  ? consentCookie?.value?.includes("analytics")
  : false;

if (consentAnalytics) {
  authObject.auth = auth;
}

function handleConsentPreferencesUpdated(event) {
  const acceptedCategories = event.detail.acceptedCategories;
  authObject.auth = auth;
  logEvent(analytics, "consent_preferences_updated", {
    acceptedCategories: acceptedCategories,
  });
  document.dispatchEvent(
    new Event("cookie_consent_preferences_updated_finished")
  );
}

// Добавление обработчика событий
window.addEventListener(
  "cookie_consent_preferences_updated",
  handleConsentPreferencesUpdated
);

export { authObject };
