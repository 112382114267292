import _ from "lodash";

import { Favorites, metadata, Promoted } from "@core/models";

import { EntityBaseCache } from "./entity.base.cache";
import { EntityCore } from "./entity.core";

export class EntityCoreCache extends EntityBaseCache {
  // ==================== Class Properties ====================
  /** class code */
  static code = "entity_core_cache";

  protected static _instance: EntityCoreCache;

  // ==================== Instance Properties ====================
  protected _items: EntityCore[];

  promotedCodes: string[] = [];

  favoritesCodes: string[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  get orderByProps(): string[] {
    return metadata.get(this.classCode, "orderByProps", this.kind) as string[];
  }

  get usesFavorites(): boolean {
    return metadata.get(this.classCode, "usesFavorites", this.kind) as boolean;
  }

  get usesPromoted(): boolean {
    return metadata.get(this.classCode, "usesPromoted", this.kind) as boolean;
  }

  protected async initInstance() {
    this._items = [];

    // await this.filterParams.init();
    await this.fetchInitialData();

    if (this.usesFavorites) {
      await this.fetchFavoritesCodes();
    }

    if (this.usesPromoted) {
      await this.fetchPromotedCodes();
      this.applyPromoted();
    }

    if (this.usesSubscription) {
      await this.subscribe();
    }
  }

  get entityClass() {
    return EntityCore;
  }

  get itemClass() {
    return EntityCore;
  }

  async fetchPromotedCodes() {
    return Promoted.get(this.entityClassCode).then((data) => {
      if (data) {
        this.promotedCodes = data.items;
      } else {
        this.promotedCodes = [];
      }
    });
  }

  async fetchFavoritesCodes() {
    return Favorites.get(this.entityClassCode)
      .then((data) => {
        if (data) {
          this.favoritesCodes = data.items;
        } else {
          this.favoritesCodes = [];
        }
      })
      .catch(() => {
        this.favoritesCodes = [];
      });
  }

  get favorites() {
    if (!this.usesFavorites) {
      return [];
    }
    const favoritesItems = [];
    if (this.favoritesCodes.length > 0) {
      _.forEach(this.favoritesCodes, (favoriteCode) => {
        const index = this._items.findIndex((el) => el.code === favoriteCode);
        if (index > -1) {
          favoritesItems.push(this._items[index]);
        }
      });
    }
    return favoritesItems;
  }

  async getByCode(code: string) {
    let result = null;
    const index = this._items.findIndex((el) => el.code === code);
    if (index > -1) {
      result = this._items[index];
    }
    return result;
  }

  /**  get an array of documents by codes */
  async getByCodes(codes: string[]) {
    const result = [];
    _.forEach(codes, (code) => {
      _.forEach(this._items, (item) => {
        if (item.code === code) {
          result.push(item);
        }
      });
    });
    return result;
  }

  applyPromoted() {
    const promotedItems = [];
    if (this.promotedCodes.length > 0) {
      _.forEach(this.promotedCodes, (promotedCode) => {
        const index = this._items.findIndex((el) => el.code === promotedCode);
        if (index > -1) {
          promotedItems.push(this._items[index]);
        }
      });
      const othersItems = this._items.filter(
        (item) => !this.promotedCodes.includes(item.code)
      );
      const resultingItems = [...promotedItems, ...othersItems];
      this._items = resultingItems;
    }
  }
}
