export const storageBaseUrl = "https://storage.googleapis.com";

export const languageLabels = {
  ru: "Рус.",
  uk: "Укр.",
  en: "Eng.",
};

export const defaultAvatar = "/assets/images/avatar.jpg";

export const themes = [
  {
    code: "os",
    title: "OS",
  },
  {
    code: "dark",
    title: "Dark",
  },
  {
    code: "light",
    title: "Light",
  },
];
