import { EntityBase } from "@core/models";

export class EntityCore extends EntityBase {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  code: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data?) {
    super(data);
    if (data) {
      this.code = data.code;
    } else {
      this.code = "";
    }
  }
}
