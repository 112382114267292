// import { deepCopy } from "@core/helpers";

export class Base {
  // ==================== Class Properties ====================
  /** код класса */
  static code = "";

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  class() {
    return Object.getPrototypeOf(this).constructor;
  }

  classCode() {
    return this.class().code;
  }

  className() {
    return this.class().name;
  }

  clone() {
    const cls = this.class();
    return new cls(this);
  }

  toData() {
    // const data = deepCopy(this.clone()); // clone для того чтобы убрать лишние свойства которых нет в модели
    const data = structuredClone(this.clone()); // clone для того чтобы убрать лишние свойства которых нет в модели
    return data;
  }

  toString() {
    return `Class: ${this.className()} Object: ${JSON.stringify(this)}`;
  }
}
