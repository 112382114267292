import { EntityCore } from "./entity.core";

import { config, metadata } from "@core/models";

export class EntitySummary extends EntityCore {
  // ==================== Class Properties ====================

  /** class code */
  static code = "entity_summary";

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Class Methods ====================
  static get entityClass() {
    return null;
  }

  static get collPath(): string {
    let collPathTmp = metadata.get(this.code, "collPath", "summary") as string;
    if (this.entityClass.multilingual) {
      collPathTmp = `${config.langCode}_${collPathTmp}`;
    }
    return collPathTmp;
  }

  // ==================== Instance Methods ====================
}
