import { detect } from "detect-browser";

class Browser {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  canUseAvif: boolean;
  canUseFullScreen: boolean;
  canUseWebp: boolean;
  name: string;
  os: string;
  version: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor() {
    const detectData = detect();

    this.canUseWebp = false;
    // Check for the webp class
    const webpClassElements = document.getElementsByClassName("webp");
    if (webpClassElements && webpClassElements.length > 0) {
      // supported
      this.canUseWebp = true;
    }

    this.canUseAvif = false;
    // Check for avif class
    const avifClassElements = document.getElementsByClassName("avif");
    if (avifClassElements && avifClassElements.length > 0) {
      // supported
      this.canUseAvif = true;
    }

    this.canUseFullScreen = document.fullscreenEnabled;

    this.name = detectData.name;
    this.os = detectData.os;
    this.version = detectData.version;
  }
}

export const browser = new Browser();
