import { Entity, EntitySummary } from "@core/models";
import type { MetaType } from "@core/models";

import { EntityCoreCache } from "./entity.core.cache";

export class EntitySummaryCache extends EntityCoreCache {
  // ==================== Class Properties ====================
  /** class code */
  static code = "entity_summary_cache";

  protected static _instance: EntitySummaryCache;

  // ==================== Instance Properties ====================
  protected _items: EntitySummary[];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  get entityClass() {
    return Entity;
  }

  get itemClass() {
    return this.summaryClass;
  }

  get items(): Entity[] {
    return this._items;
  }

  get kind(): MetaType {
    return "summary_cache";
  }

  get summaryClass() {
    return EntitySummary;
  }
}
