import log from "loglevel";

import { collection, doc, getDoc } from "firebase/firestore";

import type { CollectionReference } from "firebase/firestore";

import { firestore } from "@global/firebase";

import { metadata } from "@core/models";

import { Base } from "@core/models";

export class Document extends Base {
  // ==================== Class Properties ====================
  /** inner ref to collection */
  static _collRef: CollectionReference;

  // ==================== Instance Properties ====================
  /** doc id */
  id: string;

  // ==================== Class Methods ====================

  static get collPath(): string {
    return metadata.get(this.code, "collPath") as string;
  }

  /** ref to colllection */
  static get collRef() {
    if (!this._collRef) {
      this._collRef = collection(firestore, this.collPath).withConverter(
        this.converter()
      );
    }
    return this._collRef;
  }

  /** конвертация свойств типа timestamp в дату */
  static convertTimestampsFromFirestore(_data) {
    //
  }

  /**  */
  public static fromFirestore(data) {
    this.convertTimestampsFromFirestore(data);
    return new this(data);
  }

  /**  */
  static converter() {
    return {
      toFirestore: (entity) => {
        return entity;
      },
      fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        const entity = this.fromFirestore(data);
        return entity;
      },
    };
  }

  // Get a document from the database
  static async get(id: string): Promise<any> {
    let entity = null;
    const docRef = doc(this.collRef, id);

    return getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          entity = doc.data();
        } else {
          log.debug(`Document with id - ${id} not exists!`);
        }
      })
      .then(() => {
        return entity;
      })
      .catch((err) => {
        log.debug(`Error getting document with id - ${id} : ${err}`);
        return null;
      });
  }

  // ==================== Instance Methods ====================
  constructor(data?) {
    super();
    if (data) {
      this.id = data.id;
    } else {
      this.id = "";
    }
  }
}
