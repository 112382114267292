import { config, Document, metadata } from "@core/models";

export class EntityBase extends Document {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  publishedFirstAt: Date;
  publishedLastAt: Date | null;

  // ==================== Class Methods ====================

  static get collPath(): string {
    let collPathTmp = metadata.get(this.code, "collPath", "entity") as string;
    if (this.multilingual) {
      collPathTmp = `${config.langCode}_${collPathTmp}`;
    }
    return collPathTmp;
  }

  static get multilingual(): boolean {
    return metadata.get(this.code, "multilingual", "entity") as boolean;
  }

  // Uses a subscription to subscribe to changes in cache
  static get usesSubscription(): boolean {
    return metadata.get(this.code, "usesSubscription", "entity") as boolean;
  }

  static convertTimestampsFromFirestore(data) {
    super.convertTimestampsFromFirestore(data);
    if (data.publishedFirstAt) {
      data.publishedFirstAt = data.publishedFirstAt.toDate();
    }
    if (data.publishedLastAt) {
      data.publishedLastAt = data.publishedLastAt.toDate();
    }
  }

  // ==================== Instance Methods ====================
  constructor(data?) {
    super(data);
    if (data) {
      this.publishedFirstAt = data.publishedFirstAt;
      this.publishedLastAt = data.publishedLastAt;
    } else {
      this.publishedFirstAt = null;
      this.publishedLastAt = null;
    }
  }
}
