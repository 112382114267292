import { EntityCore } from "./entity.core";

export class Entity extends EntityCore {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  static get summaryClass() {
    return null;
  }

  // ==================== Instance Methods ====================
}
