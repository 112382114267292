export class ConfigurablePropertyOption {
  code: string;
  title: string;
  number: number;

  constructor(data) {
    this.code = data.code;
    this.title = data.title;
    this.number = data.number;
  }
}
