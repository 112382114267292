import { SitePage } from "@cms-core/models";

import { ArticleSummary } from "./summary";

export class Article extends SitePage {
  // ==================== Class Properties ====================
  static code = "article";

  // ==================== Instance Properties ====================
  dateOfPublication: Date;

  // ==================== Class Methods ====================

  static get summaryClass() {
    return ArticleSummary;
  }

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.dateOfPublication = data.dateOfPublication.toDate();
  }
}
