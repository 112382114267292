import { EntitySummaryCache } from "@core/models";

import { SiteCardPage, SiteCardPageSummary } from "@cms-core/models";

export class SiteCardPageSummaryCache extends EntitySummaryCache {
  // ==================== Class Properties ====================
  static code = "site_card_with_or_without_page_summary_cache";

  protected static _instance: SiteCardPageSummaryCache;

  // ==================== Instance Properties ====================
  protected _items: SiteCardPageSummary[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  get entityClass() {
    return SiteCardPage;
  }

  get itemClass() {
    return SiteCardPageSummary;
  }

  get items(): SiteCardPageSummary[] {
    return this._items;
  }

  get summaryClass() {
    return SiteCardPageSummary;
  }

  getCodeBySlug(slug) {
    let code = "";
    const items = this._items.filter((item) => item.slug === slug);
    if (items.length === 1) {
      code = items[0].code;
    }
    return code;
  }
}
