import { Entity } from "@core/models";

export class NotfoundPage extends Entity {
  // ==================== Class Properties ====================
  static code = "notfound_page";

  // ==================== Instance Properties ====================
  content: string;
  path: any;
  title: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.content = data.content;
    this.path = {};
    this.title = data.title;
  }
}
