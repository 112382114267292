import { Entity, metadata } from "@core/models";

export class SitePage extends Entity {
  // ==================== Class Properties ====================
  static code = "site_page";

  // ==================== Instance Properties ====================
  content: string;
  imageForSharingUrl: string;
  indexable: boolean;
  metaDescription: string;
  metaTitle: string;
  path: any;
  title: string;

  // ==================== Class Methods ====================

  static get codeHybrid(): string {
    return metadata.get(this.code, "codeHybrid", "entity") as string;
  }

  // ==================== Instance Methods ====================
  constructor(data?) {
    super(data);
    if (data) {
      this.content = data.content;
      this.imageForSharingUrl = data.imageForSharingUrl;
      this.indexable = data.indexable;
      this.metaDescription = data.metaDescription;
      this.metaTitle = data.metaTitle;
      this.path = data.path;
      this.title = data.title;
    } else {
      this.content = "";
      this.imageForSharingUrl = "";
      this.indexable = false;
      this.metaDescription = "";
      this.metaTitle = "";
      this.path = "";
      this.title = "";
    }
  }
}
