import { getPlatforms } from "@ionic/core";

class Device {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  isAndroid: boolean;
  isDesktop: boolean;
  isIOS: boolean;
  isIpad: boolean;
  isIphone: boolean;
  isMobile: boolean;
  isPhablet: boolean;
  isPhone: boolean;
  isTablet: boolean;
  OSName: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor() {
    const platforms = getPlatforms(window);

    this.isDesktop = platforms.includes("desktop");
    this.isMobile = platforms.includes("mobile");
    this.isIphone = platforms.includes("iphone");
    this.isIpad = platforms.includes("ipad");
    this.isPhone =
      platforms.includes("mobile") && !platforms.includes("tablet");
    this.isPhablet = platforms.includes("phablet");
    this.isTablet = platforms.includes("tablet");
    this.isIOS = platforms.includes("ios");
    this.isAndroid = platforms.includes("android");

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows") !== -1)
      OSName = "windows";
    if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "unix";
    if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "linux";

    if (OSName === "Mac/iOS") {
      if (platforms.includes("ios")) {
        OSName = "ios";
      } else {
        OSName = "mac";
      }
    }
    this.OSName = OSName;
  }
}

export const device = new Device();
