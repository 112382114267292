import { Entity, metadata } from "@core/models";

export class SiteCardPage extends Entity {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  content: string;
  hasPage: boolean;
  imageForSharingUrl: string;
  indexable: boolean;
  metaDescription: string;
  metaTitle: string;
  path: any;
  slug: string;
  title: string;

  // ==================== Class Methods ====================
  static get codeHybrid(): string {
    return metadata.get(this.code, "codeHybrid", "entity") as string;
  }

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.content = data.content;
    this.hasPage = data.hasPage;
    this.imageForSharingUrl = data.imageForSharingUrl;
    this.indexable = data.indexable;
    this.metaDescription = data.metaDescription;
    this.metaTitle = data.metaTitle;
    this.path = data.path;
    this.slug = data.slug;
    this.title = data.title;
  }
}
