import { EntityBaseCache, Promoted } from "@core/models";

export class PromotedCache extends EntityBaseCache {
  // ==================== Class Properties ====================
  static code = "promoted_cache";

  protected static _instance: PromotedCache;

  // ==================== Instance Properties ====================
  protected _items: Promoted[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  get entityClass() {
    return Promoted;
  }

  get itemClass() {
    return Promoted;
  }

  get items(): Promoted[] {
    return this._items;
  }
}
