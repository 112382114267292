import { ConfigurablePropertyOption } from "./property-option";

export class ConfigurableProperty {
  code: string;
  options: ConfigurablePropertyOption[];

  constructor(data) {
    this.code = data.code;
    this.options = data.options.map(
      (option) => new ConfigurablePropertyOption(option)
    );
  }
}
