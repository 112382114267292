import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore, setLogLevel } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

import { Env } from "@stencil/core";

// setLogLevel("debug"); // "debug" | "error" | "silent"
setLogLevel("silent"); // "debug" | "error" | "silent"

const firebaseConfig = JSON.parse(Env.firebaseConfig);

export const firebase = initializeApp(firebaseConfig);

export const analytics = getAnalytics(firebase);

export const database = getDatabase(firebase);

export const firestore = getFirestore(firebase, "public");

export const auth = getAuth(firebase);

export const preformance = getPerformance(firebase);
