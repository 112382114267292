import { createStore } from "@stencil/store";

import {
  presentToastConnectionOnline,
  presentToastConnectionOffline,
} from "@core/helpers";

const { state, onChange } = createStore({
  cblocks: {},
  contentEl: null,
  headerEl: null,
  isFooterVisible: true,
  isOnline: true,
  pagePath: null,
  prodboardPagePath: "",
  referrer: null,
  routerEl: null,
  theme: "",
  user: null,
  userConsent: false,
  visitorActivityHappened: false,
});

onChange("isOnline", (value) => {
  if (value) {
    presentToastConnectionOnline();
  } else {
    presentToastConnectionOffline();
  }
});

onChange("theme", (value) => {
  if (value === "dark") {
    document.body.classList.toggle("dark");
    document.body.classList.remove("light");
  }

  if (value === "light") {
    document.body.classList.toggle("light");
    document.body.classList.remove("dark");
  }
});

export default state;
