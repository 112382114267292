import _ from "lodash";

import log from "loglevel";

import { Subject } from "rxjs";

import { getDocs, onSnapshot } from "firebase/firestore";

import type { Unsubscribe } from "firebase/firestore";

import { metadata } from "@core/models";

import type { MetaType } from "@core/models";

import type { OrderBy } from "@core/types";

import { EntityBase } from "./entity.base";

export class EntityBaseCache {
  // ==================== Class Properties ====================
  /** class code */
  static code = "entity_base_cache";

  protected static _instance: EntityBaseCache;

  // ==================== Instance Properties ====================
  protected classCode: string;

  entityClassCode: string;

  protected _items: EntityBase[];

  observableEntityAdded: Subject<unknown>;
  observableEntityModified: Subject<unknown>;
  observableEntityRemoved: Subject<unknown>;

  subscriptionEntityActions: Unsubscribe;

  // ==================== Class Methods ====================
  static async init() {
    if (!this._instance) {
      this._instance = new this();
      await this._instance.initInstance();
    }
  }

  static async getInstance(): Promise<any> {
    if (!this._instance) {
      this._instance = new this();
      await this._instance.initInstance();
    }
    return this._instance;
  }

  // ==================== Instance Methods ====================

  constructor() {
    this._items = null;
    this.classCode = this.class.code as string;
    this.entityClassCode = this.entityClass.code as string;
  }

  get kind(): MetaType {
    return "entity_cache";
  }

  // Uses a subscription to subscribe to changes in cache
  get usesSubscription(): boolean {
    return metadata.get(
      this.classCode,
      "usesSubscription",
      this.kind
    ) as boolean;
  }

  get orderByDefault(): OrderBy {
    return metadata.orderByDefault(this.classCode, this.kind) as OrderBy;
  }

  protected async initInstance() {
    this._items = [];

    await this.fetchInitialData();

    if (this.usesSubscription) {
      await this.subscribe();
    }
  }

  get entityClass() {
    return EntityBase;
  }

  get itemClass() {
    return EntityBase;
  }

  get items(): EntityBase[] {
    return this._items;
  }

  get itemsCount() {
    return this._items.length;
  }

  async fetchInitialData() {
    const items = [];
    await getDocs(this.itemClass.collRef)
      .then((docs) => {
        _.forEach(docs.docs, (doc) => {
          const entity = new this.itemClass(doc.data());
          entity.id = doc.id;
          items.push(entity);
        });
      })
      .then(() => {
        this._items = _.orderBy(items, "publishedFirstAt", "desc");
        // this._items = _.orderBy(items, this.orderBy.prop, this.orderBy.dir);
      })
      .catch((err) => {
        log.debug(`Error getting list : ${err}`);
      });
  }

  get class() {
    return Object.getPrototypeOf(this).constructor;
  }

  async subscribe() {
    if (!this.observableEntityAdded) {
      this.observableEntityAdded = new Subject();
    }
    if (!this.observableEntityModified) {
      this.observableEntityModified = new Subject();
    }
    if (!this.observableEntityRemoved) {
      this.observableEntityRemoved = new Subject();
    }

    this.subscriptionEntityActions = onSnapshot(
      this.entityClass.collRef,
      (snapshot) => {
        _.forEach(snapshot.docChanges(), (change) => {
          if (change.type === "added") {
            const data = change.doc.data();
            const id = change.doc.id;
            data.id = id;
            const entity = new this.itemClass(data);
            const index = this._items.findIndex((item) => item.id === id);
            if (index === -1) {
              this._items.push(entity);
              this.observableEntityAdded.next(entity);
            }
          }
          if (change.type === "modified") {
            const data = change.doc.data();
            const id = change.doc.id;
            data.id = id;
            const entity = new this.itemClass(data);
            const index = _.findIndex(this._items, (item) => item.id === id);
            if (index > -1) {
              this._items[index] = entity;
              this.observableEntityModified.next(entity);
            } else {
              this._items.push(entity);
              this.observableEntityAdded.next(entity);
            }
          }
          if (change.type === "removed") {
            const id = change.doc.id;
            this._items = _.filter(this._items, (entity) => entity.id !== id);
            this.observableEntityRemoved.next(change.doc);
          }
        });
      }
    );

    return this.subscriptionEntityActions;
  }
}
