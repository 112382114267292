export class ImageForCard {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================

  /** image alt */
  alt: string;
  /** image title */
  title: string;
  /** image url */
  url: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  constructor(data) {
    this.alt = data.alt;
    this.title = data.title;
    this.url = data.url;
  }
}
