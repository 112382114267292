import _ from "lodash";

import { EntitySummaryCache } from "@core/models";

import { Article, ArticleSummary } from "@cms-basic/models";

export class ArticleSummaryCache extends EntitySummaryCache {
  // ==================== Class Properties ====================
  /** class code */
  static code = "article_summary_cache";

  protected static _instance: ArticleSummaryCache;

  // ==================== Instance Properties ====================
  protected _items: ArticleSummary[] = [];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  get entityClass() {
    return Article;
  }

  get itemClass() {
    return ArticleSummary;
  }

  get items(): ArticleSummary[] {
    return this._items;
  }

  get summaryClass() {
    return ArticleSummary;
  }
}
