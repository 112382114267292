import { logEvent } from "firebase/analytics";

import { getFunctions, httpsCallable } from "firebase/functions";

import { analytics, firebase } from "@global/firebase";

import state from "@global/store";

import { config } from "@core/models";

export function userInfo(uid, users) {
  if (Boolean(users[uid])) {
    const user = users[uid];
    return `${user.firstName} ${user.lastName} `;
  } else {
    return "";
  }
}

export const userThemeToState = (theme) => {
  state.theme = theme;
  // switch (theme) {
  //   case "os":
  //     const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
  //     toggleDarkTheme(prefersDark.matches);
  //     prefersDark.addListener((mediaQuery) =>
  //       toggleDarkTheme(mediaQuery.matches)
  //     );

  //     function toggleDarkTheme(shouldAdd) {
  //       if (shouldAdd) {
  //         state.theme = "dark";
  //       } else {
  //         state.theme = "light";
  //       }
  //     }
  //     break;
  //   case "dark":
  //     state.theme = "dark";
  //     break;
  //   case "light":
  //     state.theme = "light";
  //     break;
  // }
};

// export const deepCopy = <T>(target: T): T => {
//   if (target === null) {
//     return target;
//   }
//   if (target instanceof Date) {
//     return new Date(target.getTime()) as any;
//   }
//   if (target instanceof Timestamp) {
//     return target;
//   }
//   if (target instanceof Array) {
//     const cp = [] as any[];
//     (target as any[]).forEach((v) => {
//       cp.push(v);
//     });
//     return cp.map((n: any) => deepCopy<any>(n)) as any;
//   }
//   // if (typeof target === "object" && target !=== {}) {
//   if (typeof target === "object") {
//     const cp = { ...(target as { [key: string]: any }) } as {
//       [key: string]: any;
//     };
//     Object.keys(cp).forEach((k) => {
//       cp[k] = deepCopy<any>(cp[k]);
//     });
//     return cp as T;
//   }
//   return target;
// };

export function fbCallableFunction(name: string) {
  const functions = getFunctions(firebase, config.functionsRegion);
  return httpsCallable(functions, `site-${name}`);
}

export function formatDate(dt: Date) {
  let result = "";
  if (dt) {
    result = dt.toLocaleDateString(config.langCode, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  }
  return result;
}

export async function goTo404(langCode: string) {
  const path = `/${langCode}/404`;
  if (state.routerEl) {
    state.routerEl.push(path);
  } else {
    document.location.href = path;
  }
}

export async function openInnerLink(path: string) {
  if (state.routerEl) {
    state.routerEl.push(path);
  } else {
    document.location.href = path;
  }
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const wait = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// export function eventLog(eventName, data) {
//   logEvent(analytics, eventName, data);
// }

export function eventLog(eventName, data) {
  // Обработка события visitor_activity
  const visitorActivityEvents = config.visitorActivityEvents;
  const visitorActivityEventData = {
    source_event_name: eventName,
  };
  if (
    visitorActivityEvents.includes(eventName) &&
    !state.visitorActivityHappened
  ) {
    logEvent(
      analytics,
      config.visitorActivityEventName,
      visitorActivityEventData
    );
    state.visitorActivityHappened = true;
  }
  logEvent(analytics, eventName, data);
}

// export async function openURL(
//   url: string | undefined | null
// ): Promise<boolean> {
//   if (url != null && url[0] !== "#" && url.indexOf("://") === -1) {
//     const router = document.querySelector("ion-router");
//     if (router) {
//       await router.componentOnReady();
//       return router.push(url);
//     }
//   }
//   return false;
// }

// export async function openURL(
//   win: Window,
//   url: string | undefined | null
// ): Promise<boolean> {
//   if (url != null && url[0] !== "#" && url.indexOf("://") === -1) {
//     const router = win.document.querySelector("ion-router");
//     if (router) {
//       await router.componentOnReady();
//       return router.push(url);
//     }
//   }
//   return false;
// }
