import { SitePage } from "@cms-core/models";

export class ContentPage extends SitePage {
  // ==================== Class Properties ====================
  static code = "content_page";

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
}
