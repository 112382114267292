import _ from "lodash";

import { Env } from "@stencil/core";

class Config {
  // ==================== Class Properties ====================
  private static _instance: Config;

  // ==================== Instance Properties ====================
  // icons: { [key: string]: string };
  _langCode: string;

  // ==================== Class Methods ====================
  static async init() {
    if (!Config._instance) {
      Config._instance = new Config();
    }
  }

  static async getInstance(): Promise<any> {
    if (!this._instance) {
      this._instance = new this();
      // await this._instance.initInstance();
    }
    return this._instance;
  }

  static get companyInfo() {
    return Config._instance.companyInfo;
  }

  static get currency() {
    return Config._instance.currency;
  }

  static get countryCode() {
    return Config._instance.countryCode;
  }

  static get debug(): boolean {
    return Config._instance.debug;
  }

  static get defaultLangCode(): string {
    return Config._instance.defaultLangCode;
  }

  static get functionsRegion(): string {
    return Config._instance.functionsRegion;
  }

  static get indexable(): boolean {
    return Config._instance.indexable;
  }

  static get googleMapsKey(): string {
    return Config._instance.googleMapsKey;
  }

  static get langCode(): string {
    return Config._instance._langCode;
  }

  static set langCode(value: string) {
    Config._instance._langCode = value;
  }

  static get languageCodes() {
    return Config._instance.languageCodes;
  }

  static get languageDefaultCode(): string {
    return Config._instance.languageDefaultCode;
  }

  static get menuCode(): string {
    return Config._instance.menuCode;
  }

  static get prodboardId(): string {
    return Config._instance.prodboardId;
  }

  static get prodboardPagePath() {
    return Config._instance.prodboardPagePath;
  }

  static get prodboardPlannerId() {
    return Config._instance.prodboardPlannerId;
  }

  static get siteRoutes() {
    return Config._instance.siteRoutes;
  }

  static get storageBaseUrl() {
    return Config._instance.storageBaseUrl;
  }

  static get storageBucket() {
    return Config._instance.storageBucket;
  }

  static get tutorialVideoId() {
    return Config._instance.tutorialVideoId;
  }

  // ==================== Instance Methods ====================

  get companyInfo() {
    return JSON.parse(Env.companyInfo);
  }

  get currency(): string {
    return Env.currency;
  }

  get countryCode(): string {
    return Env.countryCode;
  }

  get debug(): boolean {
    return JSON.parse(Env.debug);
  }

  get defaultLangCode(): string {
    return Env.languageDefaultCode;
  }

  get functionsRegion(): string {
    return Env.functionsRegion;
  }

  get indexable(): boolean {
    return JSON.parse(Env.indexable);
  }

  get googleMapsKey(): string {
    return Env.googleMapsKey;
  }

  get langCode(): string {
    return this._langCode;
  }

  set langCode(value: string) {
    this._langCode = value;
  }

  get languageCodes() {
    const result = JSON.parse(Env.languageCodes);
    return result;
  }

  get languageDefaultCode(): string {
    return Env.languageDefaultCode;
  }

  get menuCode(): string {
    return Env.menuCode;
  }

  get prodboardId(): string {
    return Env.prodboardId;
  }

  get prodboardPagePath() {
    const result = JSON.parse(Env.prodboardPagePath);
    return result;
  }

  get prodboardPlannerId() {
    const result = JSON.parse(Env.prodboardPlannerId);
    return result;
  }

  get siteRoutes() {
    const result = JSON.parse(Env.siteRoutes);
    return result;
  }

  get storageBaseUrl() {
    return "https://storage.googleapis.com";
  }

  get storageBucket(): string {
    return JSON.parse(Env.firebaseConfig).storageBucket;
  }

  get tutorialVideoId() {
    const result = JSON.parse(Env.tutorialVideoId);
    return result;
  }

  get visitorActivityEventName() {
    return Env.visitorActivityEventName;
  }

  get visitorActivityEvents() {
    const result = JSON.parse(Env.visitorActivityEvents);
    return result;
  }
}

export const config = new Config();
