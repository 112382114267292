// import log from "loglevel";

// import { doc, getDoc } from "firebase/firestore";

import { EntityBase } from "@core/models";

import { ConfigurableProperty } from "./property";

export class ConfigurableProperties extends EntityBase {
  // ==================== Class Properties ====================
  static code = "configurable_properties";

  // ==================== Instance Properties ====================
  props: ConfigurableProperty[];

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.props = data.props.map((prop) => new ConfigurableProperty(prop));
  }
}
