import { Entity } from "@core/models";

export class SiteMenu extends Entity {
  // ==================== Class Properties ====================
  static code = "site_menu";

  // ==================== Instance Properties ====================
  items: Array<Object>;

  // ==================== Class Methods ====================
  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.items = data.items;
  }
}
