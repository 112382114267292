import { EntitySummary } from "@core/models";

export class SiteCardPageSummary extends EntitySummary {
  // ==================== Class Properties ====================

  // ==================== Instance Properties ====================
  hasPage: boolean;
  imageForSharingUrl: string;
  indexable: boolean;
  metaDescription: string;
  metaTitle: string;
  path: any;
  slug: string;
  title: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
  constructor(data) {
    super(data);
    this.hasPage = data.hasPage;
    this.imageForSharingUrl = data.imageForSharingUrl;
    this.indexable = data.indexable;
    this.metaDescription = data.metaDescription;
    this.metaTitle = data.metaTitle;
    this.path = data.path;
    this.slug = data.slug;
    this.title = data.title;
  }
}
