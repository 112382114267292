import { SitePage } from "@cms-core/models";

export class HybridPage extends SitePage {
  // ==================== Class Properties ====================
  static code = "hybrid_page";

  // ==================== Instance Properties ====================
  subTitle: string;

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================

  // ==================== Instance Methods ====================
  constructor(data?) {
    super(data);
    if (data) {
      this.subTitle = data.subTitle;
    } else {
      this.subTitle = "";
    }
  }
}
