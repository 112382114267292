import i18next from "i18next";

import {
  alertController,
  loadingController,
  modalController,
  toastController,
} from "@ionic/core";

export async function presentToast(
  message: string,
  kind: "error" | "info" | "success"
): Promise<void> {
  let color: string;
  let icon: string;
  let header: string;
  let cssClass = ["toast-custom"];

  switch (kind) {
    case "info":
      color = "medium";
      icon = "information-circle-outline";
      header = "info";
      break;
    case "success":
      color = "success";
      icon = "checkmark-circle-outline";
      header = "success";
      break;
    case "error":
      color = "danger";
      icon = "warning-outline";
      header = "error";
      break;
  }
  const toast = await toastController.create({
    animated: true,
    buttons: [
      {
        side: "end",
        icon: "close",
        role: "cancel",
      },
    ],
    color: color,
    duration: 6000,
    icon: icon,
    header: i18next.t(`${header}`),
    htmlAttributes: { tabindex: undefined }, // Позволяет решить проблему с фокусом всех input элементов, во время активного ion-toast
    message: message,
    cssClass: cssClass,
  });
  await toast.present();
  await toast.onWillDismiss();
}

export async function presentToastSimple(message: string, cssClass?: string) {
  const toast = await toastController.create({
    message: message,
    duration: 3000,
    cssClass: cssClass,
    htmlAttributes: { tabindex: undefined }, // Позволяет решить проблему с фокусом всех input элементов, во время активного ion-toast
  });
  await toast.present();
  await toast.onWillDismiss();
}

export async function presentLoading(message: string) {
  const loadingElement = await loadingController.create({
    spinner: "lines",
    message: message,
  });
  loadingElement.present();
  return loadingElement;
}

export async function presentAlertConfirm(message: string, okFunc) {
  const alert = await alertController.create({
    header: "Подтверждение",
    message: message,
    buttons: [
      {
        text: "Нет",
        role: "cancel",
        cssClass: "secondary",
        handler: () => {},
      },
      {
        text: "Да",
        handler: () => {
          okFunc();
        },
      },
    ],
  });
  return await alert.present();
}

export async function presentAlertInfo(
  header: any,
  message: string,
  callback?: any
) {
  const alert = await alertController.create({
    header: header,
    message: message,
    buttons: [{ text: "OK", cssClass: "btn-color" }],
  });

  await alert.present();
  if (callback) {
    await alert.onDidDismiss().then(() => callback());
  }
}

export const presentToastConnectionOnline = () => {
  presentToastSimple(
    i18next.t("app-root_connectionStatus_online"),
    "toast-connection-status-online"
  );
};

export const presentToastConnectionOffline = () => {
  presentToastSimple(
    i18next.t("app-root_connectionStatus_offline"),
    "toast-connection-status-offline"
  );
};

export async function presentSignin() {
  const modal = await modalController.create({
    component: "signin-modal",
    cssClass: "signin-modal",
  });
  modal.onDidDismiss();
  await modal.present();
}
