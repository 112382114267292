import { Featuring } from "./featuring";

export class Promoted extends Featuring {
  // ==================== Class Properties ====================
  static code = "promoted";

  // ==================== Instance Properties ====================

  // ==================== Class Methods ====================

  // ==================== Instance Methods ====================
}
